<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_u", {
                        m: $t("message.users"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.username')"
                            prop="name"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.name"
                                v-model="form.name"
                                :placeholder="$t('message.username')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.surname')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.surname"
                                v-model="form.surname"
                                :placeholder="$t('message.surname')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.first_name')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.first_name"
                                v-model="form.first_name"
                                :placeholder="$t('message.first_name')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.tel_number')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.tel_number"
                                v-model="form.tel_number"
                                :placeholder="$t('message.tel_number')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.email')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.email"
                                v-model="form.email"
                                :placeholder="$t('message.email')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.gender')"
                            class="label_mini"
                        >
                            <el-select
                                v-model="form.gender"
                                :placeholder="$t('message.gender')"
                                :size="'medium'"
                                class="w-100"
                            >
                                <el-option label="Н/О" value="no"></el-option>
                                <el-option
                                    :label="$t('message.male')"
                                    value="men"
                                ></el-option>
                                <el-option
                                    :label="$t('message.female')"
                                    value="women"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.password')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.password"
                                v-model="form.password"
                                :placeholder="$t('message.password')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.role_id')"
                            class="label_mini"
                        >
                            <select-roles
                                :id="form.role_id"
                                v-model="form.role_id"
                                :size="'medium'"
                            >
                            </select-roles>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.status')"
                            class="label_mini"
                        >
                            <el-select
                                size="medium"
                                class="w-100"
                                filterable
                                clearable
                                v-model="form.status"
                                :placeholder="$t('message.status')"
                            >
                                <el-option label="Активный" value="active">
                                </el-option>
                                <el-option label="Не активний" value="deactive">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.identification_code')"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.identification_code"
                                v-model="form.identification_code"
                                :placeholder="$t('message.identification_code')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->
                     <el-col :span="8">
                        <el-form-item
                            :label="$t('message.region')"
                            class="label_mini"
                            prop="region_id"
                        >
                        <select-regional-setting v-model="form.region_id" :id="form.region_id" size="medium"></select-regional-setting>
                        </el-form-item>
                    </el-col>
                    <!-- end col   -->
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "users/rules",
            model: "users/model",
            columns: "users/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "users/update",
            show: "users/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.country_id = 1;
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
